.logo {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 0 0 100%;
	max-width: 400px;

	// @include media-breakpoint-up(xs) {
    //     margin: 0 10px;
	// 	margin-bottom: 20px;
	// 	max-width: 160px;
	// }
	// @include media-breakpoint-up(md) {
	// 	margin: 0;
	// 	max-width: 400px;
	// }
}
