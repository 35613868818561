.phone_top {
	color: $color-primary;

	@include media-breakpoint-up(xs) {
		font-size: 20px;
	}
	@include media-breakpoint-up(md) {
		font-size: 30px;
	}

	span {
		display: flex;
		align-items: center;
		cursor: pointer;
		text-align: center;
		background-color: #146db7;
		border: 0;
		border-color: #045169;
		border-style: solid;
		border-radius: 3px;

		box-sizing: border-box;
		padding: 12px 33px;
		line-height: 1;
		font-family: $font-family-black;

		@include media-breakpoint-up(xs) {
			font-size: 16px;
		}
		@include media-breakpoint-up(md) {
			font-size: 20px;
		}
	}
}
