.sl_form {
	position: absolute;
	top: 135px;
	right: 20px;
	width: 254px;
	padding: 12px;
	border-radius: 5px;
	background: #fff;
	background: rgba(255, 255, 255, 0.8);

	.name {
		color: #301a13;
		font-size: 18px;
		margin-bottom: 7px;
		text-align: center;
	}

	.inp_wrap {
		margin-bottom: 12px;
	}

	.inp_wrap input[type="text"], .inp_wrap input[type="tel"] {
		margin-bottom: 2px;
		width: 100%;
		background: #fff;
		border: none;
		height: 47px;
		line-height: 47px;
		padding: 0 20px;
		font-size: 15px;
		color: #737373;
	}

	.inp_wrap input[type="text"]:first-child, .inp_wrap input[type="tel"]:first-child {
		border-radius: 2px 2px 0 0;
	}

	.inp_wrap input[type="text"]:last-child, .inp_wrap input[type="tel"]:last-child {
		border-radius: 0 0 2px 2px;
	}

	.but {
		width: 100%;
	}
}
