.site_name {
    text-transform: uppercase;
    line-height: 0.9;
    font-family: "Roboto-Medium", sans-serif;

    @include media-breakpoint-up(xs) {
        margin: 0 10px;
		margin-bottom: 20px;
		font-size: 20px;
	}
	@include media-breakpoint-up(md) {
		margin: 0;
		font-size: 30px;
	}
}
