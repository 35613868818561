.advantage {
	color: #fff;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

	&__img {
		height: 150px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;

		img {
			max-height: 100%;
		}
	}

	&__content {
		font-size: 16px;
		text-align: center;
	}
}
