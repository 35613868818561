.directions {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 60px;
	

	// @include media-breakpoint-up(xs) {
	// 	margin-bottom: 25px;
	// }
	// @include media-breakpoint-up(sm) {
	// 	margin: 0;
	// }

	&__img {
		display: flex;
		align-items: center;
		justify-content: center;
		max-width: 230px;
		max-height: 230px;
		margin-bottom: 30px;


		img {
			max-height: 100%;
			border-radius: 50%;
		}
	}

	&__content {
		// padding-left: 20px;
		font-family: "Roboto-Medium", sans-serif;
		text-align: center;
	}
}
