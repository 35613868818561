///* ========================================================================
//   #FONTS
//   ======================================================================== */

// Переменные, содержашие font-family по назначению
$font-family-copy: "Roboto-Regular", sans-serif;
$font-family-heading: "Roboto-Black", sans-serif;
$font-family-subheading: "Roboto-Black", sans-serif;
$font-family-button: "FiraSans-Book", sans-serif;

$font-family-black: "Roboto-Black", sans-serif;
$font-family-bold: "Roboto-Bold", sans-serif;
$font-family-menu: "FiraSans-Light", sans-serif;
$font-family-regular: "FiraSans-Regular", sans-serif;

//$font-family-accent: "OpenSans-Semibold", sans-serif;
//$font-family-note: "OpenSans-Light", sans-serif;

$font-family-creative: "Tiff-Heavy", sans-serif;

$font-size-default: $default-size;
