.type-car {
	max-width: 80%;
	margin: 0 auto;
	padding-bottom: 60px;
	// border-bottom: 1px solid #1e2933;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	// &:last-child {
	// 	border-bottom: none;
	// }

	&__heding {
		text-align: center;
		padding: 15px;
		padding-bottom: 25px;
		font-size: 24px;
		font-weight: bold;
	}

	&__img {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;

		img {
			margin: 0 20px 15px;


		}
	}

	&__content {
	}

	&__row {
		display: flex;
		background-color: #ffc88b;
		&:nth-child(2n) {
			background-color: lighten(#ffc88b, 20%);
		}
	}

	&__col {
		padding: 5px;
		flex: 0 0 50%;
		max-width: 50%;

		&--one {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
}
