/* ==========================================================================
   #SECTION
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

.section {
    @include clearfix; // padding: 0 10px;
    font-family: $font-family-copy;
    width: 100%;
    margin: 0 auto;
}

.section--header {

}

.section--banner {
    min-height: 100px;
    padding-top: 70px;
    padding-bottom: 50px;
    background-color: lighten($color-secondary, 20%);
}

.section--directions {
    //margin-bottom: 40px;
}

.section--type-car {
    //margin-bottom: 40px;
}

.section--form {
    padding: 30px 0;
    background-color: #1e2933;
}


.section--advantage {
    background-color: lighten($color-secondary, 20%);
    padding: 40px 0;
}

.section--footer {
    background-color: #1e2933;
    padding: 30px 0 60px;
}

.section--bottom {
    background-color: #222222;
}